// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#map, #main {
  height: 100vh;
}

#text {
  overflow: auto;
  margin-right: 1rem;
  height: 99vh;
}

.menu {
  width: 10rem;
  height: 100vh;
}

small {
  font-size: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./examples/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["html, body {\n  margin: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n#map, #main {\n  height: 100vh;\n}\n\n#text {\n  overflow: auto;\n  margin-right: 1rem;\n  height: 99vh;\n}\n\n.menu {\n  width: 10rem;\n  height: 100vh;\n}\n\nsmall {\n  font-size: 0.7rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
